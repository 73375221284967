<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    width="1200px"
    scrollable
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card min-height="570">
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span
            class="headline"
          >{{ 'Extrato Fornecedor ' + moment(dadosDialog.data_referencia).format('MMMM/YY') }}</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card
        outlined
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="dadosDialog.razao_social"
                label="Razão Social"
                outlined
                rounded
                dense
                readonly
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="dadosDialog.telefones"
                label="Telefone"
                outlined
                rounded
                dense
                readonly
              />
            </v-col>
          </v-row>
          <v-card
            outlined
            style="border-radius: 20px;"
          >
            <v-row class="ma-1">
              <v-col
                cols="12"
                md="9"
              >
                <v-card
                  outlined
                  height="315"
                >
                  <v-simple-table style="height: 300px; overflow: auto;">
                    <thead>
                      <tr>
                        <th class="text-left pr-2">
                          Código
                        </th>
                        <th class="text-left px-2">
                          Descrição
                        </th>
                        <th class="text-right px-2">
                          Crédito
                        </th>
                        <th class="text-right pl-2">
                          Débito
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in dadosDialog.resumo_eventos"
                        :key="index"
                      >
                        <td
                          class="text-right pr-2"
                          width="20"
                          style="font-weight: 500;"
                        >
                          {{ item.codigo }}
                        </td>
                        <td
                          class="text-left px-2"
                          style="font-weight: 500;"
                        >
                          {{ item.descricao_evento }}
                        </td>
                        <td
                          class="text-right px-2"
                          width="120"
                          style="font-weight: 500;"
                        >
                          <span v-if="item.tipo_evento === 0">
                            {{ "R$ " + currencyFormatter(item.valor_calculo) }}
                          </span>
                        </td>
                        <td
                          class="text-right pl-2"
                          width="120"
                          style="font-weight: 500;"
                        >
                          <span v-if="item.tipo_evento === 1">
                            {{ "R$ " + currencyFormatter(item.valor_calculo) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <vuetify-money
                      v-model="dadosDialog.valor_saldo_inicial"
                      label="Saldo Anterior"
                      :options="money"
                      class="text-direita"
                      outlined
                      rounded
                      readonly
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <vuetify-money
                      v-model="dadosDialog.valor_credito"
                      label="Total Crédito"
                      :options="money"
                      class="text-direita"
                      outlined
                      rounded
                      readonly
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <vuetify-money
                      v-model="dadosDialog.valor_debito"
                      label="Total Débito"
                      :options="money"
                      class="text-direita"
                      outlined
                      rounded
                      readonly
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <vuetify-money
                      :value="valueFormat()"
                      :label="labelSaldoFinal() === 'P'? 'Saldo a Pagar' : 'Saldo a Receber'"
                      :options="money"
                      :class="labelSaldoFinal() === 'P' ? 'text-direita-red' : 'text-direita-blue'"
                      style="font-weight: 600;"
                      readonly
                      outlined
                      rounded
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card-actions
        class="justify-end mt-0"
      >
        <v-btn
          class="text-none text-white"
          color="error"
          rounded
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Fechar
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="primary"
          depressed
          rounded
          @click="exportPdf"
        >
          <v-icon
            dark
            left
          >
            mdi-printer
          </v-icon>
          Imprimir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import rules from '../../utils/formRules'
  import { CLOSE } from '@/store/modules/extratoFornecedores'
  import { mapState, mapMutations } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import jsPDF from 'jspdf'
  import VuetifyMoney from '../../components/base/VuetifyMoney.vue'
  import moment from 'moment'

  export default {
    name: 'ExtratoDialog',
    components: { VuetifyMoney },
    props: {
      dadosDialog: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        rules,
        currencyFormatter,
        valid: false,
        prefixo: 'R$',
        snackbar: { message: '', show: false, color: 'error' },
        headers: [
          { align: 'right', sortable: true, groupable: false, text: 'Codigo', value: 'codigo' },
          { align: 'left', text: 'Descrição', value: 'descricao_evento' },
          { align: 'left', text: 'Crédito', value: 'valor_credito' },
          { align: 'left', text: 'Débito', value: 'valor_debito' },
        ],
        money: {
          locale: 'pt-BR',
          precision: 2,
          prefix: 'R$',
        },
      }
    },
    computed: {
      ...mapState('extratoFornecedores', ['loading', 'dialog']),
    },
    methods: {
      ...mapMutations('extratoFornecedores', [CLOSE]),
      cancel () {
        this.CLOSE()
      },
      valueFormat () {
        if (parseFloat(this.dadosDialog.valor_saldo_final) < 0) {
          return (Math.abs(parseFloat(this.dadosDialog.valor_saldo_final)))
        }
        return this.dadosDialog.valor_saldo_final
      },
      labelSaldoFinal () {
        if (parseFloat(this.dadosDialog.valor_saldo_final) < 0) {
          return 'P'
        } else {
          return 'R'
        }
      },
      exportPdf () {
        const dados = this.dadosDialog
        var relatorio = new jsPDF()
        function addHeader () {
          relatorio.addImage(window.location.origin + '/img/logo_branca.png', 'png', margin.left, 5, 20, 20)
          relatorio.setFontSize(15)
          relatorio.text('Extrato Fornecedor', docWidth / 2, 15, { align: 'center' })
          relatorio.setFontSize(10)
          relatorio.text('Data: ' + moment(new Date()).format('DD/MM/YYYY'), 175, 17)
          relatorio.text('Hora: ' + moment(new Date()).format('hh:mm:ss'), 175, 25)
        }
        function addFooter (pageNumber) {
          relatorio.setFontSize(9)
          relatorio.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - margin.bottom, { align: 'right' })
        }
        function cabeca () {
          relatorio.setFillColor(10, 10, 10)
          relatorio.line(5, 30, pageWidth - 5, 30)
          relatorio.line(5, 30, 5, 50)
          relatorio.line(pageWidth - 5, 30, pageWidth - 5, 50)
          relatorio.setFontSize(8)
          relatorio.text('Empresa', 10.7, 34)
          relatorio.setFontSize(10)
          relatorio.text(dados.razao_social, 10, 38)
          relatorio.setFontSize(8)
          relatorio.text('CNPJ', 10.7, 43)
          relatorio.setFontSize(10)
          relatorio.text(dados.cnpj, 10, 47)
          relatorio.setFontSize(8)
          relatorio.text('Telefone', 95.7, 43)
          relatorio.setFontSize(10)
          relatorio.text(dados.telefones[0], 95, 47)
          relatorio.setFontSize(8)
          relatorio.text('Período', 170.7, 43)
          relatorio.setFontSize(10)
          relatorio.text(moment(dados.data_referencia).format('MM/YYYY'), 170, 47)

          addHeader()
        }
        function formatarDialog () {
          var dadoFormat = []
          if (dados.resumo_eventos) {
            dados.resumo_eventos.forEach((item) => {
              if (item.tipo_evento === 0) {
                dadoFormat.push({
                  codigo: item.codigo,
                  descricao_evento: item.descricao_evento,
                  valor_credito: 'R$ ' + currencyFormatter(item.valor_calculo),
                })
              } else {
                dadoFormat.push({
                  codigo: item.codigo,
                  descricao_evento: item.descricao_evento,
                  valor_debito: 'R$ ' + currencyFormatter(item.valor_calculo),
                })
              }
            })
            return dadoFormat
          }
          return []
        }
        function labelSaldoFinal () {
          if (parseFloat(dados.valor_saldo_final) < 0) {
            return 'Saldo a Pagar'
          } else {
            return 'Saldo a Receber'
          }
        }
        var totalPages = '{total_pages_count_string}'
        const stylesBorder = { lineWidth: 0.2, lineColor: 10 }
        const docWidth = relatorio.internal.pageSize.width
        const docHeight = relatorio.internal.pageSize.height
        var pageSize = relatorio.internal.pageSize
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
        const margin = { left: 10, right: 10, bottom: 10 }

        relatorio.autoTable({
          theme: 'striped',
          styles: stylesBorder,
          headStyles: { textColor: 0, lineWidth: 0.2, fontSize: 8, cellPadding: 1, fillColor: [255, 255, 255] },
          bodyStyles: { textColor: 0 },
          columnStyles: {
            codigo: { lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 }, cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 }, halign: 'right', cellWidth: 17 },
            descricao_evento: { lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 }, cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 } },
            valor_credito: { lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 }, cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 }, halign: 'right', cellWidth: 28 },
            valor_debito: { lineWidth: { top: 0, bottom: 0, left: 0.2, right: 0.2 }, cellPadding: { top: 0.5, bottom: 0.5, left: 1.5, right: 1.5 }, halign: 'right', cellWidth: 28 },
          },
          columns: [
            { dataKey: 'codigo', header: 'Código' },
            { dataKey: 'descricao_evento', header: 'Descrição' },
            { dataKey: 'valor_credito', header: 'Crédito' },
            { dataKey: 'valor_debito', header: 'Débito' },
          ],
          body: formatarDialog(),
          startY: 50,
          pageBreak: 'auto',
          margin: { left: 5, top: 50, bottom: (margin.bottom + 5), right: 5 },
          didParseCell: (data) => {
            if (data.row.section === 'body' && data.row.index === 0) {
              data.cell.styles.lineWidth = { top: 0.2, bottom: 0, left: 0.2, right: 0.2 }
              data.cell.styles.cellPadding = { top: 1.5, bottom: 0.5, left: 1.5, right: 1.5 }
            } else if (data.row.section === 'body' && data.row.index === data.table.body.length - 1) {
              data.cell.styles.cellPadding = { top: 0.5, bottom: 1, left: 1.5, right: 1.5 }
            }
          },
          willDrawPage: (data) => {
            cabeca()
          },
          didDrawPage: (data) => {
            addFooter(data.pageNumber)
          },
        })
        const finalYprincipal = relatorio.lastAutoTable.finalY
        relatorio.line(5, relatorio.lastAutoTable.finalY, pageWidth - 5, relatorio.lastAutoTable.finalY)
        relatorio.autoTable({
          theme: 'plain',
          styles: { halign: 'center', lineWidth: { left: 0.2, right: 0.2 }, lineColor: 10, minCellWidth: 50 },
          headStyles: { fontSize: 8, cellPadding: { top: 1, bottom: 0.5, left: 1, right: 1 } },
          bodyStyles: { fontSize: 10, cellPadding: { top: 0.5, bottom: 1, left: 1, right: 1 } },
          margin: { left: 5, top: 50, right: 5 },
          columns: [
            { dataKey: 'valor_saldo_inicial', header: 'Saldo Anterior' },
            { dataKey: 'valor_credito', header: 'Total Crédito' },
            { dataKey: 'valor_debito', header: 'Total Débito' },
            { dataKey: 'valor_saldo_final', header: labelSaldoFinal() },
          ],

          body: [
            {
              valor_saldo_inicial: 'R$ ' + currencyFormatter(dados.valor_saldo_inicial),
              valor_credito: 'R$ ' + currencyFormatter(dados.valor_credito),
              valor_debito: 'R$ ' + currencyFormatter(dados.valor_debito),
              valor_saldo_final: 'R$ ' + currencyFormatter(Math.abs(parseFloat(dados.valor_saldo_final))),
            },
          ],
          startY: relatorio.lastAutoTable.finalY,
        })
        relatorio.line(5, relatorio.lastAutoTable.finalY, pageWidth - 5, relatorio.lastAutoTable.finalY)
        relatorio.line(5, finalYprincipal, 5, relatorio.lastAutoTable.finalY)
        relatorio.line(pageWidth - 5, finalYprincipal, pageWidth - 5, relatorio.lastAutoTable.finalY)
        if (typeof relatorio.putTotalPages === 'function') {
          relatorio.putTotalPages(totalPages)
        }
        relatorio.save('Pedido_.pdf')
      },
    },
  }
</script>
<style lang="css">
.text-field-table.v-text-field--outline input {
    text-align: right !important;
}
.text-direita input {
  text-align: right;
}

.text-direita-blue input {
  text-align: right;
  color: #0800ff !important;
}

.text-direita-red input {
  text-align: right;
  color: #ff0000 !important;
}
.centered-input input {
      text-align: right;
      font-weight: bold;
    }
</style>
